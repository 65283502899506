import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import WindowPane from "../../components/windowpane/windowpane"
import Savings from "../../components/savings/savings"

import desktopHero from "../../images/clinical-trial-results/2.1_1440x540_Desktop_Hero_1X.png"
import mobileHero from "../../images/clinical-trial-results/2.1_543x800_Mobile_Hero_2X.png"
import gradientOne from "../../images/clinical-trial-results/gradientOne.png"
import gradientTwo from "../../images/clinical-trial-results/gradientTwo.svg"
import gradientThree from "../../images/clinical-trial-results/gradientThree.png"

import pillBanner from "../../images/clinical-trial-results/pillBanner.png"
import pillBannerMobile from "../../images/clinical-trial-results/pillBannerMobile.png"

import fallingPills from "../../images/clinical-trial-results/fallingPills.png"

import magnifyingGlass from "../../images/clinical-trial-results/magnifyingGlass.svg"
import heartInHands from "../../images/clinical-trial-results/2.1_Heart_Hands_icon-01.png"

import heartChart from "../../images/clinical-trial-results/heartGray_Desktop.png"
import heartChartMobile from "../../images/clinical-trial-results/heartChart_Mobile.png"

import laptop from "../../images/protect-against-another-heart-attack/laptop_q.png"


import ctaBackground from "../../images/sign-up/ctaBackground.svg"

export default () => (
  <Layout>
    <SEO
      title="Clinical Trial Results"
      keywords="VASCEPA clinical trials, VASCEPA clinical studies, VASCEPA trials"
      description="Learn more about VASCEPA® (icosapent ethyl) clinical trial results in statin-treated patients with high triglycerides. Please see Indication and Important Safety Information."
    />

    <Hero
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      alt="Doctor image"
      additionalClassName="height640"
      headline={[
        "25% added ",
        <br />,
        "heart protection ",
        <br />,
        " is possible?",
        <br />,
        " Really?",
      ]}
      content={[
        <span className="m-p-r-0" style={{ paddingRight: "50px" }}>
          A groundbreaking 2018 study* proved that VASCEPA lowers your chance of a life-threatening cardiovascular event (such as a heart attack or stroke) by 25%<sup style={{position: "relative", fontSize: "14px", top: "-8px", color: "#4d4d4d", lineHeight: "1em"}}>&dagger;</sup> when added to a statin.<sup style={{position: "relative", fontSize: "14px", top: "-8px", color: "#4d4d4d", lineHeight: "1em"}}>&Dagger;</sup>
          <br />
          
        </span>,
        <p style={{marginTop: "10px", marginBottom:"0px", textIndent:"-5px"}} className="footnote-font">
        *REDUCE-IT
        <sup
          style={{
            fontSize: "10px",
            lineHeight: "0px",
            color: "black",
            fontWeight: "300",
          }}
        >
          ®
        </sup>{" "}
        study <i>N Engl J Med.</i> 2019;380(1):11-22.
      </p>,
        <p
          style={{
            textIndent:"-5px",
            marginBottom:"0px"
          }}
          className="footnote-font"
        >
          <sup className="footnote-font">&dagger;</sup>In adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.
        </p>,
        <p
          style={{
            textIndent:"-5px"
          }}
          className="footnote-font"
        >
          <sup className="footnote-font">&Dagger;</sup>In a clinical study, patients treated with VASCEPA and a statin had fewer
          cardiovascular (CV) events (17.2%) compared to those who took placebo
          (22%).
        </p>,
      ]}
    />

    <WindowPane
      mobileImage={heartChartMobile}
      leftBackground="rgb(231, 231, 234)"
      desktopImage={heartChart}
      alt="Icon of a heart representing Persistant Cardiovascular Risk (P-CVR), the cardiovascular risk that reminas even when taking a statin and managing cardiovascular  risk factors. "
      whiteBorderOne="whiteborderRight"
      backgroundOne={gradientOne}
      colorOne=""
      headlineOne="How much ground did this study actually break? "
      contentOne={[
        "When medical societies saw the results, they immediately recognized the active ingredient in VASCEPA, icosapent ethyl, in their guidelines.",
        <br />,
        <br />,
        "Why? CV disease is an epidemic, and a 25% reduction in risk",
        <sup className="footnote-font" style={{color:"#ffffff"}}>&Dagger;</sup>,
        " on top of statins is a very significant breakthrough.",
      ]}
      contentTwo={[
        "To be clear, statins are still recommended for high cholesterol, but even with a statin, significant CV risk can remain. We call this:",
      ]}
      colorTwo="#4D4D4D"
      headlineTwo={[
        <span className="magnifyingGlass" style={{ color: "#D40C8C" }}>
          Persistent Cardiovascular Risk (P-CVR):
        </span>,
      ]}
      contentThree="The CV risk that remains even if you’re taking a statin and managing CV risk factors like high blood pressure and high cholesterol."
      contentFour="Ask your doctor about your individual risk."
      footer="<sup className='footnote-font'>&Dagger;</sup>In a clinical study, patients treated with VASCEPA and a statin had fewer
      CV events (17.2%) compared to those who took placebo
      (22%)."
      backgroundColorTwo="#E7E7EA"
    />

    <div
      style={{ overflow: "hidden", position: "relative", minHeight: "250px" }}
      className="mobile-550 row block"
    >
      <img
        style={{ minHeight: "100%", position: "absolute", right: "0px" }}
        src={pillBanner}
        className="gradientbackground mobile-d-none"
        alt="Clear VASCEPA® (icosapent ethyl) capsule"
      ></img>
      <img
        src={pillBannerMobile}
        className="mobileHeroBackgroundThree img-autowidth"
        alt="Clear VASCEPA® (icosapent ethyl) capsule"
      ></img>
      <div
        style={{
          marginLeft: "0px",
          marginTop: "40px",
          marginBottom: "40px",
          paddingLeft: "40px",
        }}
        className="col-md-6 m-t-210 align-mobile-header-bottom mobile-pill-banner"
      >
        <p
          className="headerTwoMobileHero"
          style={{ color: "#1d355e", fontSize: "22px", lineHeight: "30px" }}
        >
          Until now, if you are on a statin and have CV disease and high triglycerides (&ge;150 mg/dL), there hasn’t been a proven way to reduce your P-CVR.{" "}
        </p>
        <p
          className=" headerMobileHero"
          style={{ color: "#1d355e", fontSize: "30px", lineHeight: "36px", marginBottom:"0px" }}
        >
          But now there’s VASCEPA, the first and only medication approved by the FDA
          for this indication.{" "}
        </p>
        <p style={{fontSize:"9px", marginTop:"30px", marginBottom:"0px", color: "#1d355e"}}>
          Capsule is not actual size.
        </p>
      </div>
    </div>

    <div className="block row">
      <div
        style={{ padding: "0px", minHeight: "300px"}}
        className="col-md-7 whiteborderRight block-content background-gradient-two"
      >
        <div className="row">
          <div className="col-md-5 order-md-2">
            <img
              alt="Heart Hands icon"
              style={{
                marginTop: "40px",
                display: "inherit",
                marginLeft: "0",
                marginRight: "auto",
              }}
              className="align-img-onMobile"
              src={heartInHands}
            ></img>
          </div>
          <div
            className="col-md-7 order-md-1 p-l-40 mobile-p-l-20"
            style={{ height: "100%", paddingRight: "4%" }}
          >
            <h3
              className="heroColor"
              style={{
                color: "#1D355E",
                fontSize: "30px",
                lineHeight: "38px",
                fontWeight: "700",
                zIndex: "1",
                position: "relative",
                marginTop: "5%",
                marginBottom: "auto",
              }}
            >
              Protect your heart
              <br /> with an additional
              <br />{" "}
              <span style={{ fontSize: "74px", lineHeight: "74px" }}>25%</span>
              <br /> CV risk reduction<sup>&Dagger;</sup> when you add VASCEPA to your
              statin.
            </h3>
          </div>
          <div className="col-md-12 order-md-3">
            <h3
              className="m-p-l-20"
              style={{
                fontSize: "18px",
                lineHeight: "20px",
                fontWeight: "500",
                zIndex: "1",
                position: "relative",
                marginTop: "3%",
                marginBottom: "20px;",
                color: "#4D4D4D",
                paddingLeft: "25px",
                marginBottom: "20px",
                paddingRight: "25px"
              }}
            >
              If you cannot take statins, talk to your doctor about
              <br /> whether or not VASCEPA can help you.
            </h3>
            <p style={{textIndent:"-5px", fontSize:"10px",paddingLeft: "25px",color: "#4D4D4D", paddingRight:"25px"}}>
              <sup>&Dagger;</sup>In a clinical study, patients treated with VASCEPA and a statin had fewer CV events (17.2%) compared to those who took placebo (22%).
            </p>
          </div>
        </div>
      </div>

      <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-5 block-content "
      >
        <div className="row">
          <h4
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              color: "#1D355E",
              marginTop: "5%",
              marginBottom: "0px",
            }}
          >
            How we got here
          </h4>
          <p
            className="font-margins"
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              fontWeight: "500",
              zIndex: "1",
              position: "relative",
              color: "#4D4D4D",
              marginTop: "5%",
              marginBottom: "auto",
            }}
          >
            <ul>
              <li style={{ paddingBottom: "10px" }}>
                Landmark REDUCE-IT trial included over 8000
                statin-treated patients with high triglycerides and lasted
                nearly 5 years
              </li>
              <li style={{ paddingBottom: "10px" }}>
                In addition to taking a statin, most patients were also taking
                medication to manage conditions like high blood pressure
              </li>
              <li class="mobile-p-b-5">
                In this study, patients treated with VASCEPA had fewer
                CV events (17.2%) compared to those who took placebo
                (22%)
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>

    <div className="block row">
      <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-5 block-content whiteborderRight "
      >
        <div>
          <h4
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "36px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              color: "#1D355E",
              margin: "25px"
              
            }}
          >
            How VASCEPA reduces CV risk
          </h4>
          <p
            className="font-margins"
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              fontWeight: "500",
              zIndex: "1",
              position: "relative",
              color: "#1D355E",
              margin: "25px"
            }}
          >
            VASCEPA is the first and only FDA-approved medication to lower CV
            risk in addition to other medications, such as statins, in patients
            with heart disease or diabetes and other risk factors. The clinical
            effects of VASCEPA are believed to include multiple factors. The
            exact science of how VASCEPA works in your body is not fully
            understood, but years of clinical testing have led to proven
            clinical results in lowering P-CVR with VASCEPA. Ask your doctor
            about heart protection with VASCEPA.
          </p>
        </div>
      </div>

      <div className="col-md-7 block-content">
        <img class="background-gradient" src={gradientThree} alt=""></img>
        <div style={{ display: "table", height: "100%" }}>
          <h3
            className="font-margins mobile-p-y-7percent"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              paddingLeft: "8%",
              paddingRight: "8%",
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            Even if you’re taking a statin or other medications for high blood pressure, VASCEPA offers the additional
            CV protection your heart needs
          </h3>
        </div>
      </div>
    </div>

    <RightCta
      ctaBackground={ctaBackground}
      colOne="7"
      colTwo="5"
      colThree="0"
      // whiteborder={"width90"}
      image={laptop}
      mobileImage={laptop}
      alt="Laptop icon"
      headline="Are you at greater risk than you think?"
      cta={["Take the quiz ", <span aria-hidden="true">&gt;</span>]}
      url="/cardiovascular-risk/quiz"
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      whiteborder="transparentborderRight"
    />

    <LeftCta
      colOne="7"
      colTwo="5"
      whiteborder="whiteborder"
      video={true}
      alt="Clear VASCEPA® (icosapent ethyl) capsules"
      iframe={[
        <iframe
          title="Dropping Pills"
          src="https://player.vimeo.com/video/400606075?background=1"
          width="640"
          height="468"
          frameborder="0"
          allow="fullscreen"
          allowfullscreen
        ></iframe>,
      ]}
      mobileIframe={[
        <iframe
          title="Dropping Pills"
          src="https://player.vimeo.com/video/400606092?background=1"
          width="640"
          height="353"
          frameborder="0"
          allow="fullscreen"
          allowfullscreen
          className="mobile-iframe"
        ></iframe>,
      ]}
      background={gradientThree}
      headline="Questions about taking FDA-approved VASCEPA capsules?"
      url="/getting-started/dosing"
      cta={["Taking VASCEPA ", <span aria-hidden="true">&gt;</span>]}
      contentSubClass="left"
      ctaSubClass="left"
    />
  </Layout>
)
